export type IMiniTypeOptionKey = keyof typeof miniTypeOptionsMap;
export type IMiniNameOptionKey = keyof typeof miniNameOptionMap;

export const miniTypeOptionsMap = {
    release: '正式版',
    trial: '体验版',
    develop: '开发版',
};

export const miniNameOptionMap = {
    ynnc: '一挪挪车',
};
